import './Header.svelte.css';
/* src/components/Header.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	listen,
	mount_component,
	run_all,
	safe_not_equal,
	space,
	text,
	toggle_class,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import "./lib/theme.css";
import Text from "./lib/Text.svelte.js";
import { TextSize } from "./lib/types.js";
import media from "./lib/media.js";
import Link from "./lib/Link.svelte.js";
import { onMount } from "../../_snowpack/pkg/svelte.js";
import Typewriter from "../../_snowpack/pkg/typewriter-effect/dist/core.js";

function create_default_slot_2(ctx) {
	let t;

	return {
		c() {
			t = text("Apply For Funding");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (57:3) <Link onclick={toggle(false)} size={xs?TextSize.Header:TextSize.Small} href="#general-partners">
function create_default_slot_1(ctx) {
	let t;

	return {
		c() {
			t = text("Our Team");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (59:3) <Link onclick={toggle(false)} size={xs?TextSize.Header:TextSize.Small} href="https://blog.snocap.vc">
function create_default_slot(ctx) {
	let t;

	return {
		c() {
			t = text("Our Writing");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

function create_fragment(ctx) {
	let header;
	let div0;
	let t0;
	let div2;
	let div1;
	let text_1;
	let t1;
	let nav;
	let link0;
	let t2;
	let br0;
	let t3;
	let link1;
	let t4;
	let br1;
	let t5;
	let link2;
	let nav_data_aos_value;
	let t6;
	let img1;
	let img1_src_value;
	let t7;
	let img2;
	let img2_src_value;
	let current;
	let mounted;
	let dispose;

	text_1 = new Text({
			props: { id: "typewriter", size: TextSize.Title }
		});

	link0 = new Link({
			props: {
				onclick: /*toggle*/ ctx[2](false),
				size: /*xs*/ ctx[1] ? TextSize.Header : TextSize.Small,
				href: "#founders",
				$$slots: { default: [create_default_slot_2] },
				$$scope: { ctx }
			}
		});

	link1 = new Link({
			props: {
				onclick: /*toggle*/ ctx[2](false),
				size: /*xs*/ ctx[1] ? TextSize.Header : TextSize.Small,
				href: "#general-partners",
				$$slots: { default: [create_default_slot_1] },
				$$scope: { ctx }
			}
		});

	link2 = new Link({
			props: {
				onclick: /*toggle*/ ctx[2](false),
				size: /*xs*/ ctx[1] ? TextSize.Header : TextSize.Small,
				href: "https://blog.snocap.vc",
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			header = element("header");
			div0 = element("div");
			div0.innerHTML = `<a href="#home"><img class="link svelte-1whbobt" src="/img/logo-text.svg" alt="SNØCAP"/></a>`;
			t0 = space();
			div2 = element("div");
			div1 = element("div");
			create_component(text_1.$$.fragment);
			t1 = space();
			nav = element("nav");
			create_component(link0.$$.fragment);
			t2 = space();
			br0 = element("br");
			t3 = space();
			create_component(link1.$$.fragment);
			t4 = space();
			br1 = element("br");
			t5 = space();
			create_component(link2.$$.fragment);
			t6 = space();
			img1 = element("img");
			t7 = space();
			img2 = element("img");
			attr(div0, "class", "logo svelte-1whbobt");
			attr(div0, "data-aos", "fade-down");
			attr(div1, "data-aos", "fade-right");
			attr(div1, "class", "title col-sm-7 col-xs-12");
			attr(nav, "data-aos", nav_data_aos_value = /*xs*/ ctx[1] ? "none" : "fade");
			attr(nav, "class", "col-sm-5 col-xs-12 svelte-1whbobt");
			toggle_class(nav, "open", /*open*/ ctx[0]);
			attr(img1, "class", "closer svelte-1whbobt");
			attr(img1, "alt", "close navigation menu");
			if (img1.src !== (img1_src_value = "/img/close-icon.svg")) attr(img1, "src", img1_src_value);
			attr(img2, "class", "opener svelte-1whbobt");
			attr(img2, "alt", "open navigation menu");
			if (img2.src !== (img2_src_value = "/img/menu-icon.svg")) attr(img2, "src", img2_src_value);
			attr(div2, "class", "row");
			attr(header, "class", "with-gutter svelte-1whbobt");
			toggle_class(header, "xs", /*xs*/ ctx[1]);
		},
		m(target, anchor) {
			insert(target, header, anchor);
			append(header, div0);
			append(header, t0);
			append(header, div2);
			append(div2, div1);
			mount_component(text_1, div1, null);
			append(div2, t1);
			append(div2, nav);
			mount_component(link0, nav, null);
			append(nav, t2);
			append(nav, br0);
			append(nav, t3);
			mount_component(link1, nav, null);
			append(nav, t4);
			append(nav, br1);
			append(nav, t5);
			mount_component(link2, nav, null);
			append(div2, t6);
			append(div2, img1);
			append(div2, t7);
			append(div2, img2);
			current = true;

			if (!mounted) {
				dispose = [
					listen(img1, "click", /*toggle*/ ctx[2](false)),
					listen(img2, "click", /*toggle*/ ctx[2](true))
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			const link0_changes = {};
			if (dirty & /*xs*/ 2) link0_changes.size = /*xs*/ ctx[1] ? TextSize.Header : TextSize.Small;

			if (dirty & /*$$scope*/ 16) {
				link0_changes.$$scope = { dirty, ctx };
			}

			link0.$set(link0_changes);
			const link1_changes = {};
			if (dirty & /*xs*/ 2) link1_changes.size = /*xs*/ ctx[1] ? TextSize.Header : TextSize.Small;

			if (dirty & /*$$scope*/ 16) {
				link1_changes.$$scope = { dirty, ctx };
			}

			link1.$set(link1_changes);
			const link2_changes = {};
			if (dirty & /*xs*/ 2) link2_changes.size = /*xs*/ ctx[1] ? TextSize.Header : TextSize.Small;

			if (dirty & /*$$scope*/ 16) {
				link2_changes.$$scope = { dirty, ctx };
			}

			link2.$set(link2_changes);

			if (!current || dirty & /*xs*/ 2 && nav_data_aos_value !== (nav_data_aos_value = /*xs*/ ctx[1] ? "none" : "fade")) {
				attr(nav, "data-aos", nav_data_aos_value);
			}

			if (dirty & /*open*/ 1) {
				toggle_class(nav, "open", /*open*/ ctx[0]);
			}

			if (dirty & /*xs*/ 2) {
				toggle_class(header, "xs", /*xs*/ ctx[1]);
			}
		},
		i(local) {
			if (current) return;
			transition_in(text_1.$$.fragment, local);
			transition_in(link0.$$.fragment, local);
			transition_in(link1.$$.fragment, local);
			transition_in(link2.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(text_1.$$.fragment, local);
			transition_out(link0.$$.fragment, local);
			transition_out(link1.$$.fragment, local);
			transition_out(link2.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(header);
			destroy_component(text_1);
			destroy_component(link0);
			destroy_component(link1);
			destroy_component(link2);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let open;
	let xs;
	let $media;
	component_subscribe($$self, media, $$value => $$invalidate(3, $media = $$value));

	onMount(() => {
		const tw = new Typewriter("#typewriter", { delay: 60, pauseFor: 0 });
		let debouncer;
		let headerContent = "";
		tw.typeString(headerContent).start();

		Array.from(document.querySelectorAll("[data-header-content]")).forEach(s => {
			const options = {
				root: null,
				rootMargin: "0px",
				threshold: [0.2]
			};

			const observer = new IntersectionObserver(([{ intersectionRatio, target }]) => {
					if (intersectionRatio < options.threshold[0]) return;
					if (debouncer) clearTimeout(debouncer);

					debouncer = setTimeout(
						() => {
							var _a;

							const newHeaderContent = (_a = target.dataset.headerContent) !== null && _a !== void 0
							? _a
							: "";

							if (newHeaderContent === headerContent) return;
							headerContent = newHeaderContent;

							tw.stop().deleteAll(1).callFunction(() => {
								if (headerContent.length > 40) {
									document.documentElement.style.setProperty("--size-header", "var(--size-header-lg)");
								} else {
									document.documentElement.style.setProperty("--size-header", "var(--size-header-sm)");
								}
							}).typeString(headerContent).start();
						},
						350
					);
				},
			options);

			observer.observe(s);
		});
	});

	const toggle = _open => () => {
		$$invalidate(0, open = _open);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$media*/ 8) {
			$: $$invalidate(1, xs = !$media.sm);
		}
	};

	$: $$invalidate(0, open = false);
	return [open, xs, toggle, $media];
}

class Header extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Header;