export var FontFamily;
(function(FontFamily2) {
  FontFamily2["Regular"] = "regular";
  FontFamily2["Mono"] = "mono";
})(FontFamily || (FontFamily = {}));
export var TextSize;
(function(TextSize2) {
  TextSize2["Title"] = "title";
  TextSize2["Header"] = "header";
  TextSize2["Aside"] = "aside";
  TextSize2["Paragraph"] = "paragraph";
  TextSize2["Small"] = "small";
  TextSize2["XSmall"] = "xsmall";
})(TextSize || (TextSize = {}));
export var TextOrientation;
(function(TextOrientation2) {
  TextOrientation2["Horizontal"] = "horizontal";
  TextOrientation2["Vertical"] = "vertical";
})(TextOrientation || (TextOrientation = {}));
