import './Person.svelte.css';
/* src/components/Person.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	create_component,
	create_slot,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	safe_not_equal,
	set_data,
	set_style,
	space,
	text,
	toggle_class,
	transition_in,
	transition_out,
	update_slot
} from "../../_snowpack/pkg/svelte/internal.js";

import Text from "./lib/Text.svelte.js";
import media from "./lib/media.js";
import { TextSize, FontFamily } from "./lib/types.js";

function create_default_slot_1(ctx) {
	let t;

	return {
		c() {
			t = text(/*name*/ ctx[0]);
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*name*/ 1) set_data(t, /*name*/ ctx[0]);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (15:3) <Text family={FontFamily.Mono} size={TextSize.Paragraph}>
function create_default_slot(ctx) {
	let current;
	const default_slot_template = /*#slots*/ ctx[6].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[7], null);

	return {
		c() {
			if (default_slot) default_slot.c();
		},
		m(target, anchor) {
			if (default_slot) {
				default_slot.m(target, anchor);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 128)) {
					update_slot(default_slot, default_slot_template, ctx, /*$$scope*/ ctx[7], dirty, null, null);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (default_slot) default_slot.d(detaching);
		}
	};
}

function create_fragment(ctx) {
	let div3;
	let div0;
	let text0;
	let t0;
	let p;
	let text1;
	let t1;
	let div2;
	let div1;
	let t2;
	let a;
	let img;
	let img_src_value;
	let div3_data_aos_value;
	let current;

	text0 = new Text({
			props: {
				size: TextSize.Header,
				$$slots: { default: [create_default_slot_1] },
				$$scope: { ctx }
			}
		});

	text1 = new Text({
			props: {
				family: FontFamily.Mono,
				size: TextSize.Paragraph,
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			div3 = element("div");
			div0 = element("div");
			create_component(text0.$$.fragment);
			t0 = space();
			p = element("p");
			create_component(text1.$$.fragment);
			t1 = space();
			div2 = element("div");
			div1 = element("div");
			t2 = space();
			a = element("a");
			img = element("img");
			attr(div0, "class", "text col-sm-8 col-xs-12 svelte-sbl35p");
			attr(div1, "class", "figure svelte-sbl35p");
			set_style(div1, "background-image", "url('" + /*headshotURI*/ ctx[2] + "')");
			attr(img, "alt", "linkedin page");
			if (img.src !== (img_src_value = "/img/linkedin.svg")) attr(img, "src", img_src_value);
			attr(img, "class", "svelte-sbl35p");
			attr(a, "class", "social svelte-sbl35p");
			attr(a, "href", /*linkedinURI*/ ctx[1]);
			attr(div2, "class", "image col-sm-4 col-xs-12 svelte-sbl35p");
			attr(div3, "data-aos", div3_data_aos_value = /*reverse*/ ctx[3] ? "fade-right" : "fade-left");
			attr(div3, "class", "row end-xs start-sm svelte-sbl35p");
			toggle_class(div3, "reverse", /*reverse*/ ctx[3]);
		},
		m(target, anchor) {
			insert(target, div3, anchor);
			append(div3, div0);
			mount_component(text0, div0, null);
			append(div0, t0);
			append(div0, p);
			mount_component(text1, p, null);
			append(div3, t1);
			append(div3, div2);
			append(div2, div1);
			append(div2, t2);
			append(div2, a);
			append(a, img);
			current = true;
		},
		p(ctx, [dirty]) {
			const text0_changes = {};

			if (dirty & /*$$scope, name*/ 129) {
				text0_changes.$$scope = { dirty, ctx };
			}

			text0.$set(text0_changes);
			const text1_changes = {};

			if (dirty & /*$$scope*/ 128) {
				text1_changes.$$scope = { dirty, ctx };
			}

			text1.$set(text1_changes);

			if (!current || dirty & /*headshotURI*/ 4) {
				set_style(div1, "background-image", "url('" + /*headshotURI*/ ctx[2] + "')");
			}

			if (!current || dirty & /*linkedinURI*/ 2) {
				attr(a, "href", /*linkedinURI*/ ctx[1]);
			}

			if (!current || dirty & /*reverse*/ 8 && div3_data_aos_value !== (div3_data_aos_value = /*reverse*/ ctx[3] ? "fade-right" : "fade-left")) {
				attr(div3, "data-aos", div3_data_aos_value);
			}

			if (dirty & /*reverse*/ 8) {
				toggle_class(div3, "reverse", /*reverse*/ ctx[3]);
			}
		},
		i(local) {
			if (current) return;
			transition_in(text0.$$.fragment, local);
			transition_in(text1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(text0.$$.fragment, local);
			transition_out(text1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div3);
			destroy_component(text0);
			destroy_component(text1);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let reverse;
	let $media;
	component_subscribe($$self, media, $$value => $$invalidate(5, $media = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	let { name } = $$props;
	let { linkedinURI } = $$props;
	let { headshotURI } = $$props;
	let { reversed = false } = $$props;

	$$self.$$set = $$props => {
		if ("name" in $$props) $$invalidate(0, name = $$props.name);
		if ("linkedinURI" in $$props) $$invalidate(1, linkedinURI = $$props.linkedinURI);
		if ("headshotURI" in $$props) $$invalidate(2, headshotURI = $$props.headshotURI);
		if ("reversed" in $$props) $$invalidate(4, reversed = $$props.reversed);
		if ("$$scope" in $$props) $$invalidate(7, $$scope = $$props.$$scope);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*reversed, $media*/ 48) {
			$: $$invalidate(3, reverse = reversed && $media.sm);
		}
	};

	return [name, linkedinURI, headshotURI, reverse, reversed, $media, slots, $$scope];
}

class Person extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance, create_fragment, safe_not_equal, {
			name: 0,
			linkedinURI: 1,
			headshotURI: 2,
			reversed: 4
		});
	}
}

export default Person;