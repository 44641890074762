import './Home.svelte.css';
/* src/components/Home.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	append,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	safe_not_equal,
	space,
	text,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import "./lib/theme.css";
import Text from "./lib/Text.svelte.js";
import Carat from "./Carat.svelte.js";
import { FontFamily, TextSize, TextOrientation } from "./lib/types.js";

function create_default_slot(ctx) {
	let t;

	return {
		c() {
			t = text("Energy ∙ Industry ∙ Food & Land ∙ Materials ∙ Mobility");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

function create_fragment(ctx) {
	let section;
	let div;
	let aside;
	let text_1;
	let t0;
	let video;
	let video_controls_value;
	let t1;
	let carat;
	let current;

	text_1 = new Text({
			props: {
				title: "Our Sectors",
				family: FontFamily.Mono,
				orientation: TextOrientation.Vertical,
				size: TextSize.Small,
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	carat = new Carat({ props: { fragment: "founders" } });

	return {
		c() {
			section = element("section");
			div = element("div");
			aside = element("aside");
			create_component(text_1.$$.fragment);
			t0 = space();
			video = element("video");
			video.innerHTML = `<source src="/video/mountain_graded.mp4" type="video/mp4"/>`;
			t1 = space();
			create_component(carat.$$.fragment);
			attr(aside, "data-aos", "fade-right");
			attr(aside, "class", "svelte-18xio41");
			video.playsInline = true;
			video.autoplay = true;
			video.muted = true;
			video.loop = true;
			video.controls = video_controls_value = false;
			attr(video, "class", "svelte-18xio41");
			attr(div, "class", "flex svelte-18xio41");
			attr(section, "id", "home");
			attr(section, "data-header-content", "Powering platforms for <br/> a better climate future.");
			attr(section, "class", "svelte-18xio41");
		},
		m(target, anchor) {
			insert(target, section, anchor);
			append(section, div);
			append(div, aside);
			mount_component(text_1, aside, null);
			append(div, t0);
			append(div, video);
			append(section, t1);
			mount_component(carat, section, null);
			current = true;
		},
		p(ctx, [dirty]) {
			const text_1_changes = {};

			if (dirty & /*$$scope*/ 1) {
				text_1_changes.$$scope = { dirty, ctx };
			}

			text_1.$set(text_1_changes);
		},
		i(local) {
			if (current) return;
			transition_in(text_1.$$.fragment, local);
			transition_in(carat.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(text_1.$$.fragment, local);
			transition_out(carat.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(section);
			destroy_component(text_1);
			destroy_component(carat);
		}
	};
}

class Home extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default Home;