import './Contact.svelte.css';
/* src/components/Contact.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	append,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	safe_not_equal,
	space,
	text,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import "./lib/theme.css";
import Link from "./lib/Link.svelte.js";
import { TextSize } from "./lib/types.js";

function create_default_slot_1(ctx) {
	let t;

	return {
		c() {
			t = text("raising? click here!");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (11:3) <Link size={TextSize.Aside} href="mailto:hello@snocap.vc">
function create_default_slot(ctx) {
	let t;

	return {
		c() {
			t = text("general inqury? email us.");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

function create_fragment(ctx) {
	let section;
	let div1;
	let div0;
	let link0;
	let t0;
	let br0;
	let t1;
	let link1;
	let t2;
	let br1;
	let t3;
	let a0;
	let t4;
	let a1;
	let t5;
	let a2;
	let t6;
	let div3;
	let current;

	link0 = new Link({
			props: {
				size: TextSize.Aside,
				href: "/apply",
				$$slots: { default: [create_default_slot_1] },
				$$scope: { ctx }
			}
		});

	link1 = new Link({
			props: {
				size: TextSize.Aside,
				href: "mailto:hello@snocap.vc",
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			section = element("section");
			div1 = element("div");
			div0 = element("div");
			create_component(link0.$$.fragment);
			t0 = space();
			br0 = element("br");
			t1 = space();
			create_component(link1.$$.fragment);
			t2 = space();
			br1 = element("br");
			t3 = space();
			a0 = element("a");
			a0.innerHTML = `<img alt="twitter page" src="/img/twitter.svg" class="svelte-vxekpu"/>`;
			t4 = space();
			a1 = element("a");
			a1.innerHTML = `<img alt="linkedin page" src="/img/linkedin.svg" class="svelte-vxekpu"/>`;
			t5 = space();
			a2 = element("a");
			a2.innerHTML = `<img alt="medium blog" src="/img/medium.svg" class="svelte-vxekpu"/>`;
			t6 = space();
			div3 = element("div");
			div3.innerHTML = `<div data-aos="fade" class="mountains col-xs-12 svelte-vxekpu"></div>`;
			attr(a0, "class", "social svelte-vxekpu");
			attr(a0, "href", "https://twitter.com/snocapvc");
			attr(a1, "class", "social svelte-vxekpu");
			attr(a1, "href", "https://linkedin.com/company/snocapvc");
			attr(a2, "class", "social svelte-vxekpu");
			attr(a2, "href", "https://blog.snocap.vc");
			attr(div0, "data-aos", "fade");
			attr(div0, "class", "links col-sm-offset-7 col-sm-5 svelte-vxekpu");
			attr(div1, "class", "row with-gutter svelte-vxekpu");
			attr(div3, "class", "mountains-container with-gutter svelte-vxekpu");
			attr(section, "id", "contact");
			attr(section, "data-header-content", "Get in touch");
			attr(section, "class", "svelte-vxekpu");
		},
		m(target, anchor) {
			insert(target, section, anchor);
			append(section, div1);
			append(div1, div0);
			mount_component(link0, div0, null);
			append(div0, t0);
			append(div0, br0);
			append(div0, t1);
			mount_component(link1, div0, null);
			append(div0, t2);
			append(div0, br1);
			append(div0, t3);
			append(div0, a0);
			append(div0, t4);
			append(div0, a1);
			append(div0, t5);
			append(div0, a2);
			append(section, t6);
			append(section, div3);
			current = true;
		},
		p(ctx, [dirty]) {
			const link0_changes = {};

			if (dirty & /*$$scope*/ 1) {
				link0_changes.$$scope = { dirty, ctx };
			}

			link0.$set(link0_changes);
			const link1_changes = {};

			if (dirty & /*$$scope*/ 1) {
				link1_changes.$$scope = { dirty, ctx };
			}

			link1.$set(link1_changes);
		},
		i(local) {
			if (current) return;
			transition_in(link0.$$.fragment, local);
			transition_in(link1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(link0.$$.fragment, local);
			transition_out(link1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(section);
			destroy_component(link0);
			destroy_component(link1);
		}
	};
}

class Contact extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default Contact;