import './Text.svelte.css';
/* src/components/lib/Text.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	assign,
	compute_rest_props,
	create_slot,
	detach,
	element,
	exclude_internal_props,
	get_spread_update,
	init,
	insert,
	safe_not_equal,
	set_attributes,
	toggle_class,
	transition_in,
	transition_out,
	update_slot
} from "../../../_snowpack/pkg/svelte/internal.js";

import "./theme.css";
import { TextSize, FontFamily, TextOrientation } from "./types.js";

function create_fragment(ctx) {
	let span;
	let current;
	const default_slot_template = /*#slots*/ ctx[7].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[6], null);
	let span_levels = [{ class: /*className*/ ctx[0] }, /*$$restProps*/ ctx[1]];
	let span_data = {};

	for (let i = 0; i < span_levels.length; i += 1) {
		span_data = assign(span_data, span_levels[i]);
	}

	return {
		c() {
			span = element("span");
			if (default_slot) default_slot.c();
			set_attributes(span, span_data);
			toggle_class(span, "svelte-175ywr0", true);
		},
		m(target, anchor) {
			insert(target, span, anchor);

			if (default_slot) {
				default_slot.m(span, null);
			}

			current = true;
		},
		p(ctx, [dirty]) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 64)) {
					update_slot(default_slot, default_slot_template, ctx, /*$$scope*/ ctx[6], dirty, null, null);
				}
			}

			set_attributes(span, span_data = get_spread_update(span_levels, [
				(!current || dirty & /*className*/ 1) && { class: /*className*/ ctx[0] },
				dirty & /*$$restProps*/ 2 && /*$$restProps*/ ctx[1]
			]));

			toggle_class(span, "svelte-175ywr0", true);
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(span);
			if (default_slot) default_slot.d(detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let className;
	const omit_props_names = ["family","size","orientation","class"];
	let $$restProps = compute_rest_props($$props, omit_props_names);
	let { $$slots: slots = {}, $$scope } = $$props;
	let { family = FontFamily.Regular } = $$props;
	let { size = TextSize.Paragraph } = $$props;
	let { orientation = TextOrientation.Horizontal } = $$props;
	let { class: additionalClassNames = "" } = $$props;

	$$self.$$set = $$new_props => {
		$$props = assign(assign({}, $$props), exclude_internal_props($$new_props));
		$$invalidate(1, $$restProps = compute_rest_props($$props, omit_props_names));
		if ("family" in $$new_props) $$invalidate(2, family = $$new_props.family);
		if ("size" in $$new_props) $$invalidate(3, size = $$new_props.size);
		if ("orientation" in $$new_props) $$invalidate(4, orientation = $$new_props.orientation);
		if ("class" in $$new_props) $$invalidate(5, additionalClassNames = $$new_props.class);
		if ("$$scope" in $$new_props) $$invalidate(6, $$scope = $$new_props.$$scope);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*family, size, orientation, additionalClassNames*/ 60) {
			$: $$invalidate(0, className = `family-${family} size-${size} orientation-${orientation} ${additionalClassNames}`);
		}
	};

	return [
		className,
		$$restProps,
		family,
		size,
		orientation,
		additionalClassNames,
		$$scope,
		slots
	];
}

class Text extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance, create_fragment, safe_not_equal, {
			family: 2,
			size: 3,
			orientation: 4,
			class: 5
		});
	}
}

export default Text;