import './App.svelte.css';
/* src/components/App.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	attr,
	check_outros,
	create_component,
	destroy_component,
	detach,
	element,
	group_outros,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import { onMount } from "../../_snowpack/pkg/svelte.js";
import AOS from "../../_snowpack/pkg/aos.js";
import "../../_snowpack/pkg/aos/dist/aos.css";
import Header from "./Header.svelte.js";
import Home from "./Home.svelte.js";
import Founders from "./Founders.svelte.js";
import People from "./People.svelte.js";
import Contact from "./Contact.svelte.js";
import Footer from "./Footer.svelte.js";

function create_else_block(ctx) {
	let header;
	let t0;
	let home;
	let t1;
	let founders;
	let t2;
	let people;
	let t3;
	let contact;
	let t4;
	let footer;
	let current;
	header = new Header({});
	home = new Home({});
	founders = new Founders({});
	people = new People({});
	contact = new Contact({});
	footer = new Footer({});

	return {
		c() {
			create_component(header.$$.fragment);
			t0 = space();
			create_component(home.$$.fragment);
			t1 = space();
			create_component(founders.$$.fragment);
			t2 = space();
			create_component(people.$$.fragment);
			t3 = space();
			create_component(contact.$$.fragment);
			t4 = space();
			create_component(footer.$$.fragment);
		},
		m(target, anchor) {
			mount_component(header, target, anchor);
			insert(target, t0, anchor);
			mount_component(home, target, anchor);
			insert(target, t1, anchor);
			mount_component(founders, target, anchor);
			insert(target, t2, anchor);
			mount_component(people, target, anchor);
			insert(target, t3, anchor);
			mount_component(contact, target, anchor);
			insert(target, t4, anchor);
			mount_component(footer, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(header.$$.fragment, local);
			transition_in(home.$$.fragment, local);
			transition_in(founders.$$.fragment, local);
			transition_in(people.$$.fragment, local);
			transition_in(contact.$$.fragment, local);
			transition_in(footer.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(header.$$.fragment, local);
			transition_out(home.$$.fragment, local);
			transition_out(founders.$$.fragment, local);
			transition_out(people.$$.fragment, local);
			transition_out(contact.$$.fragment, local);
			transition_out(footer.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(header, detaching);
			if (detaching) detach(t0);
			destroy_component(home, detaching);
			if (detaching) detach(t1);
			destroy_component(founders, detaching);
			if (detaching) detach(t2);
			destroy_component(people, detaching);
			if (detaching) detach(t3);
			destroy_component(contact, detaching);
			if (detaching) detach(t4);
			destroy_component(footer, detaching);
		}
	};
}

// (20:1) {#if route === "apply"}
function create_if_block(ctx) {
	let div;

	return {
		c() {
			div = element("div");
			div.textContent = "Redirecting...";
			attr(div, "class", "apply svelte-3x82nj");
		},
		m(target, anchor) {
			insert(target, div, anchor);
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let current_block_type_index;
	let if_block;
	let current;
	const if_block_creators = [create_if_block, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*route*/ ctx[0] === "apply") return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx, -1);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			div = element("div");
			if_block.c();
			attr(div, "class", "app container-fluid svelte-3x82nj");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			if_blocks[current_block_type_index].m(div, null);
			current = true;
		},
		p(ctx, [dirty]) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index !== previous_block_index) {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					
				}

				transition_in(if_block, 1);
				if_block.m(div, null);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if_blocks[current_block_type_index].d();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { route } = $$props;

	onMount(() => {
		AOS.init({ duration: 1000, disable: "phone" });
	});

	if (route === "apply") {
		window.location.href = "https://form-23cf11.zapier.app/apply-to-snocap";
	}

	$$self.$$set = $$props => {
		if ("route" in $$props) $$invalidate(0, route = $$props.route);
	};

	return [route];
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { route: 0 });
	}
}

export default App;