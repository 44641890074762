import './Link.svelte.css';
/* src/components/lib/Link.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	assign,
	attr,
	compute_rest_props,
	create_component,
	create_slot,
	destroy_component,
	detach,
	element,
	exclude_internal_props,
	get_spread_object,
	get_spread_update,
	init,
	insert,
	is_function,
	listen,
	mount_component,
	safe_not_equal,
	transition_in,
	transition_out,
	update_slot
} from "../../../_snowpack/pkg/svelte/internal.js";

import "./theme.css";
import Text from "./Text.svelte.js";

function create_default_slot(ctx) {
	let current;
	const default_slot_template = /*#slots*/ ctx[3].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[4], null);

	return {
		c() {
			if (default_slot) default_slot.c();
		},
		m(target, anchor) {
			if (default_slot) {
				default_slot.m(target, anchor);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 16)) {
					update_slot(default_slot, default_slot_template, ctx, /*$$scope*/ ctx[4], dirty, null, null);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (default_slot) default_slot.d(detaching);
		}
	};
}

function create_fragment(ctx) {
	let a;
	let text_1;
	let current;
	let mounted;
	let dispose;
	const text_1_spread_levels = [{ class: "link text" }, /*$$restProps*/ ctx[2]];

	let text_1_props = {
		$$slots: { default: [create_default_slot] },
		$$scope: { ctx }
	};

	for (let i = 0; i < text_1_spread_levels.length; i += 1) {
		text_1_props = assign(text_1_props, text_1_spread_levels[i]);
	}

	text_1 = new Text({ props: text_1_props });

	return {
		c() {
			a = element("a");
			create_component(text_1.$$.fragment);
			attr(a, "href", /*href*/ ctx[0]);
			attr(a, "class", "svelte-kds1ko");
		},
		m(target, anchor) {
			insert(target, a, anchor);
			mount_component(text_1, a, null);
			current = true;

			if (!mounted) {
				dispose = listen(a, "click", function () {
					if (is_function(/*onclick*/ ctx[1])) /*onclick*/ ctx[1].apply(this, arguments);
				});

				mounted = true;
			}
		},
		p(new_ctx, [dirty]) {
			ctx = new_ctx;

			const text_1_changes = (dirty & /*$$restProps*/ 4)
			? get_spread_update(text_1_spread_levels, [text_1_spread_levels[0], get_spread_object(/*$$restProps*/ ctx[2])])
			: {};

			if (dirty & /*$$scope*/ 16) {
				text_1_changes.$$scope = { dirty, ctx };
			}

			text_1.$set(text_1_changes);

			if (!current || dirty & /*href*/ 1) {
				attr(a, "href", /*href*/ ctx[0]);
			}
		},
		i(local) {
			if (current) return;
			transition_in(text_1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(text_1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(a);
			destroy_component(text_1);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	const omit_props_names = ["href","onclick"];
	let $$restProps = compute_rest_props($$props, omit_props_names);
	let { $$slots: slots = {}, $$scope } = $$props;
	
	let { href } = $$props;
	let { onclick = undefined } = $$props;

	$$self.$$set = $$new_props => {
		$$props = assign(assign({}, $$props), exclude_internal_props($$new_props));
		$$invalidate(2, $$restProps = compute_rest_props($$props, omit_props_names));
		if ("href" in $$new_props) $$invalidate(0, href = $$new_props.href);
		if ("onclick" in $$new_props) $$invalidate(1, onclick = $$new_props.onclick);
		if ("$$scope" in $$new_props) $$invalidate(4, $$scope = $$new_props.$$scope);
	};

	return [href, onclick, $$restProps, slots, $$scope];
}

class Link extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { href: 0, onclick: 1 });
	}
}

export default Link;