import { n as noop, s as safe_not_equal } from './common/index-abed608f.js';

const subscriber_queue = [];
function writable(value, start = noop) {
  let stop;
  const subscribers = [];
  function set(new_value) {
    if (safe_not_equal(value, new_value)) {
      value = new_value;
      if (stop) {
        const run_queue = !subscriber_queue.length;
        for (let i = 0; i < subscribers.length; i += 1) {
          const s = subscribers[i];
          s[1]();
          subscriber_queue.push(s, value);
        }
        if (run_queue) {
          for (let i = 0; i < subscriber_queue.length; i += 2) {
            subscriber_queue[i][0](subscriber_queue[i + 1]);
          }
          subscriber_queue.length = 0;
        }
      }
    }
  }
  function update(fn) {
    set(fn(value));
  }
  function subscribe2(run, invalidate = noop) {
    const subscriber = [run, invalidate];
    subscribers.push(subscriber);
    if (subscribers.length === 1) {
      stop = start(set) || noop;
    }
    run(value);
    return () => {
      const index = subscribers.indexOf(subscriber);
      if (index !== -1) {
        subscribers.splice(index, 1);
      }
      if (subscribers.length === 0) {
        stop();
        stop = null;
      }
    };
  }
  return {set, update, subscribe: subscribe2};
}

function __pika_web_default_export_for_treeshaking__(t) {
  return writable({}, (e2) => {
    if (typeof window == "undefined")
      return;
    let n = {}, r = () => e2(function(e3) {
      let t2 = {classNames: ""}, n2 = [];
      for (let r2 in e3)
        t2[r2] = e3[r2].matches, t2[r2] && n2.push("media-" + r2);
      return t2.classNames = n2.join(" "), t2;
    }(n));
    for (let e3 in t) {
      let i = window.matchMedia(t[e3]);
      n[e3] = i, n[e3].addListener(r);
    }
    return r(), () => {
      for (let e3 in n)
        n[e3].removeListener(r);
    };
  });
}

export default __pika_web_default_export_for_treeshaking__;
