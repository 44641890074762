import './Carat.svelte.css';
/* src/components/Carat.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal
} from "../../_snowpack/pkg/svelte/internal.js";

function create_fragment(ctx) {
	let div;
	let a;
	let img;
	let img_src_value;
	let a_href_value;

	return {
		c() {
			div = element("div");
			a = element("a");
			img = element("img");
			if (img.src !== (img_src_value = "/img/carat-down.svg")) attr(img, "src", img_src_value);
			attr(img, "alt", "next section");
			attr(a, "class", "next svelte-ntu2si");
			attr(a, "href", a_href_value = "#" + /*fragment*/ ctx[0]);
			attr(div, "class", "carat with-gutter svelte-ntu2si");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, a);
			append(a, img);
		},
		p(ctx, [dirty]) {
			if (dirty & /*fragment*/ 1 && a_href_value !== (a_href_value = "#" + /*fragment*/ ctx[0])) {
				attr(a, "href", a_href_value);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { fragment } = $$props;

	$$self.$$set = $$props => {
		if ("fragment" in $$props) $$invalidate(0, fragment = $$props.fragment);
	};

	return [fragment];
}

class Carat extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { fragment: 0 });
	}
}

export default Carat;